import * as serviceWorker from './serviceWorker';

(async () => {
    try {
        let module;

        if (window.location.pathname.includes('/stamp-card/')) {
            module = await import('index.stamp-card');
        } else if (window.location.pathname.includes('/stamp-portal')) {
            module = await import('index.stamp-portal');
        } else {
            module = await import('index.portal');
        }

        module.default();
    } catch (error) {
        console.error(error);
    }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// Enable survice workers to make the website run in offline mode
serviceWorker.unregister();
